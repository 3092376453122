import Head from "next/head"
import { Box, Container, Icon } from "@chakra-ui/react"
import { Link } from "@chakra-ui/next-js"
import Logo from "public/svg/logo.svg"

export default function Home() {
  return (
    <>
      <Head>
        <title>BNI</title>
        <meta
          name="description"
          content="Custom app for BNI meetings by KPTAC"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        <link
          rel="icon"
          href="/favicon.ico"
        />
      </Head>
      <Container
        as={"main"}
        fontSize={"x-large"}
        maxW={"container.sm"}
        textAlign={"center"}
        py={8}
      >
        <Link href={"https://www.digitalemenu.com"}>
          <Icon
            as={Logo}
            width={{ base: 24 }}
            height={"auto"}
            color={"brand.primary"}
          />
          <Box>digitalemenu.com</Box>
        </Link>
      </Container>
    </>
  )
}
